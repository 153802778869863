<template>
  <div>
    <div class="uk-grid-medium" uk-grid>
      <div class="uk-width-5-5">

        <div class="d-flex">
          <nav id="breadcrumbs" class="mb-3">
            <ul>
              <li>
                <router-link :to="{name: 'company.dashboard'}">
                  <i class="uil-home-alt"></i>
                </router-link>
              </li>
              <li>{{$t('general.companies')}}</li>
              <li>{{$t('company.list.add_company')}}</li>
            </ul>
          </nav>
        </div>
        <div class="card" v-if="loading">
          <default-loading></default-loading>
        </div>
        <template v-else>
        <div class="card">
          <div v-for="error in errorMessages" v-if="errorMessages" class="uk-alert-danger" uk-alert>
            <a class="uk-alert-close" uk-close></a>
            <p style="color:red;">
              <i class="uil-exclamation-triangle"></i> {{ error }} </p>
          </div>
        </div>
        <div class="card mt-4">
          <form class="regular-form uk-padding-large" action="">

            <h4><i class="uil-store-alt"></i> {{$t('company.information')}}</h4>

            <div class="form-group row mb-1 p-2">
              <div class="col-md-6 offset-md-3">
                <label class="text-center" for="logo">{{$t('company.avatar')}}</label>
                  <div class="d-flex justify-content-center align-items-center">
                    <img class="rounded img-fluid logoarea" src="@/assets/images/no-photo.png" alt="">
                  <input type="file" class="d-none" id="avatar" @change="processFile($event)">
                  <button @click="inputClick()" type="button" class="btn btn-sm btn-outline-primary ml-2">
                    {{$t('general.image_change')}}
                  </button>
                  </div>
              </div>
            </div>
              
            <div class="position-relative uk-column-1-2 uk-column-divider">
              <div class="form-group row">
                <div class="col-md-12">
                  <label class="col-form-label" for="surname">{{$t('company.list.company_name')}}<span
                      class="required">*</span></label>
                  <input type="text" class="form-control" id="surname"
                          name="company_name" placeholder="" v-model="form.name" required="">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-12">
                  <label class="col-form-label" for="start_date">{{$t('company.contract_start_date')}}<span
                      class="required">*</span></label>
                  <input type="date" class="form-control" id="start_date"
                          name="start_date" placeholder="" v-model="form.start_date" required="">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-12">
                  <label class="col-form-label" for="end_date">{{$t('company.list.contract_expired_date')}}<span
                      class="required">*</span></label>
                  <input type="date" class="form-control" id="end_date"
                          name="end_date" placeholder="" v-model="form.end_date" required="">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-12">
                  <label class="col-form-label" for="domain">{{$t('company.domain_name')}}<span
                      class="required">*</span></label>
                  <input type="text" class="form-control" id="domain" name="domain"
                          placeholder="" v-model="form.domain" required="">
                </div>
              </div>

              <div class="form-group row domain uk-first-column">
                <div class="col-md-12">
                  <label class="col-form-label">{{$t('company.alias_name')}}<span class="required">*</span></label>
                  <div class="d-flex">
                    <input type="text" id="alias" name="alias" placeholder="" v-model="form.alias" class="form-control">
                    <span>.softinya.com</span>
                  </div>
                </div>
              </div>

              <div class="form-group row" v-if="isAdmin">
                <div class="col-md-12">
                  <label class="col-form-label" for="domain">Anzera Eğitim Pini</label>
                  <input type="text" class="form-control" v-model="form.pin" placeholder="" required="">
                </div>
              </div>
            </div>

            <div class="uk-card-footer p-0">
              <div class="float-right">
                <button @click="saveForm()" class="btn btn-primary btn-sm mt-2"
                        type="button"><i class="uil-check"></i> {{ $t('general.save') }}</button>
              </div>
            </div>
          </form>
        </div>
      </template>
    </div>
  </div>
</div>
</template>

<script>
import Vue from "vue";
import DataTable from "@/assets/components/dataTable/DataTable";
import module, {
  BASE_URL,
  MODULE_NAME,
  ERROR,
  CREATE_ITEM,
    LOADING
} from "@/core/services/store/company.module";
import store from '@/core/services'
import moment from "moment";
import $ from "jquery";
import DefaultLoading from "@/view/components/loading/DefaultLoading";

export default {
  name: "CompaniesAdd",
  components: {DataTable, DefaultLoading},
  beforeCreate () {
    function registerStoreModule (moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }
    registerStoreModule(MODULE_NAME, module)
  },
  data() {
    return {
      form : {
        type : 'company',
        name : '',
        domain : '',
        alias : '',
        start_date : moment().format('YYYY-MM-DD'),
        end_date : moment().add('1', 'year').format('YYYY-MM-DD'),
        avatar : null,
        pin: null,
      },
      errorMessages : [],
      successMessage : false,
    }
  },
  computed: {
    isAdmin() {
      let user = JSON.parse(this.secureLocalStorage("user"));
      let isAdmin = false;
      if (user && user.roles && user.roles.length) {
        isAdmin = !!user.roles.find(item => item.id == 1)
      }
      return isAdmin;
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + '/' + ERROR]
      },
      set(value) {
      }
    },
    loading() {
      return store.getters[MODULE_NAME + '/' + LOADING]
    },
  },
  methods: {
    saveForm(){
      let form = this.form;

      let formData = new FormData();

      this.errorMessages = [];
      if (form.name.length === 0) {
        this.errorMessages.push(this.$t('company.required.name'));
        this.scrollToTop();
        return false;
      }
      if (form.domain.length === 0) {
        this.errorMessages.push(this.$t('company.required.domain'));
        this.scrollToTop();
        return false;
      }
      if (form.alias.length === 0) {
        this.errorMessages.push(this.$t('company.required.alias'));
        this.scrollToTop();
        return false;
      }
      if (form.start_date.length === 0) {
        this.errorMessages.push(this.$t('company.required.start_date'));
        this.scrollToTop();
        return false;
      }
      if (form.end_date.length === 0) {
        this.errorMessages.push(this.$t('company.required.end_date'));
        this.scrollToTop();
        return false;
      }
      if (form.avatar === null) {
        this.errorMessages.push(this.$t('general.required_institution_logo'));
        this.scrollToTop();
        return false;
      }

      formData.append('type',form.type);
      formData.append('name',form.name);
      formData.append('domain',form.domain);
      formData.append('alias',form.alias);
      formData.append('start_date',form.start_date);
      formData.append('end_date',form.end_date);
      formData.append('avatar',form.avatar);
      formData.append('pin',form.pin);

      store.dispatch(MODULE_NAME + '/' + CREATE_ITEM, {
        url: BASE_URL,
        contents: formData
      }).then(()=>{
        if (!this.error) {
          this.$router.push({name: 'company.companies.list', params : {'succesMessage' : this.$t('general.update_success')}})
        } else this.errorMessages.push(this.error)
      })
    },
    processFile(event) {
      let imageInput = event.target.files[0];
      this.form.avatar = imageInput;
      let blob = URL.createObjectURL(imageInput);
      let imageHolder = $('.logoarea');
      imageHolder.attr('src', blob);
    },
    inputClick(){
      $('#avatar').click();
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
};
</script>
<style scoped>
.uk-alert{
  margin: 0;
}
</style>
